


import SECTION_OTHERSGAMEBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_OTHERSGAMECONTAINERSECTION from
'../dynamic_section/dynamic_1/template_01';





import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const OthersPageDesktop = ()=>{
  
return (
  <div id="othersPageDesktop" class="page ">
  
  

  
  < SECTION_OTHERSGAMEBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `others_game_banner` } id={ `others_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  
  
  

  
  < SECTION_OTHERSGAMECONTAINERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `Section_others` } id={ `othersContainer` }
    imageSize={`2x1`} contentPosition={`bottom`}
    actionPosition={``} rowDesktop={ 6 }
    rowMobile={ 3 } numberOfRow={ -1 }
    dataType={`game provider`} noPadding={ false } filter={`OT`}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={`start`} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  
  

  
  < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  
  

  
</div>
)
}

export default OthersPageDesktop;